import React, { Component } from 'react';

import './MainFooter.css'

export default class MainFooter extends Component {
    render() {
        return(
            <section className='MainFooter'>
                <p>Copyright Lakeshow Tix 2019</p>
            </section>
        )
    }
}